.calculator-main .send-btn {
  width: 200px;
}
.calculator-main .send-btn.ant-btn-primary {
  background-color: #02a8e4;
  border-color: #02a8e4;
  background: linear-gradient(45deg, #e62878, #786eaf 33%, #e62878 66%, #786eaf 99%, #e62878 132%, #786eaf 165%);
}
.calculator-main .send-btn.ant-btn-primary,
.calculator-main a.ant-btn-primary {
  background-size: 610% 610%;
  color: #fff;
  border: none;
}
.calculator-main .send-btn.ant-btn-primary:hover {
  background-size: 300% 300%;
  text-decoration: underline;
}
.calculator-main .send-btn.ant-btn-primary:focus {
  color: #fff;
  background-color: #028cbe;
  border-color: #0283b1;
}
.calculator-main .send-btn.ant-btn-primary:hover span,
.calculator-main .send-btn.ant-btn-primary:focus span {
  text-decoration: underline;
}
