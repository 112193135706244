.layout-black .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #191919ed;
  height: 80px;
  line-height: 1;
  padding: 0;
}
.layout-black .website-header-left {
  display: flex;
  align-items: center;
  height: 100%;
}
.layout-black .menu-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}
.layout-black .menu-box .menu-text {
  margin-top: 4px;
  font-size: 12px;
}
.layout-black .img-box {
  width: 350px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  background: #1d1d1d;
}
.layout-black .header-logo {
  width: 200px;
  margin-right: 120px;
}
.layout-black .website-header-right {
  padding-right: 12px;
}
.layout-black .menu-btn {
  cursor: pointer;
  color: #fff;
}
