/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main-login {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}
.main-login .bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-login .logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
.main-login .loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 340px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main-login .ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #457093;
  margin: 0;
}
.main-login .ant-input-affix-wrapper {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
}
.main-login .ant-input-suffix {
  position: absolute;
  top: 50%;
  right: 11px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.main-login .ant-input {
  padding: 0 11px!important;
}
.main-login .icon {
  color: #fff;
}
.main-login .btn {
  background: #457093!important;
}
